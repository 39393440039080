.heading {
  font-size: 20px;
  font-weight: bold;
  opacity: 0.6;
  margin-bottom: 16px;
}

.heading:not(:first-child) {
  margin-top: 48px;
}

.profile {
  line-height: 1.7;
}

.workItems {
  display: flex;
  flex-wrap: wrap;
}
