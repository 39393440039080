.container {
  margin-bottom: 24px;
  width: calc((100% - 24px) / 2);
}

.container:nth-child(2n) {
  margin-left: 24px;
}

@media screen and (max-width: 720px) {
  .container {
    width: 100%;
  }
  .container:nth-child(2n) {
    margin-left: 0;
  }
}

.thumbnail {
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  background-color: #3252a6;
  border-radius: 4px;
  position: relative;
}

.thumbnail img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.roles {
  font-size: 14px;
  opacity: 0.6;
  margin-top: 2px;
}

.title {
  font-weight: bold;
  margin-top: 8px;
}
